<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="3">
                <form @submit="onSubmit">
                    <v-card class="mt-20">
                        <v-card-title class="justify-center">
                            <v-img max-height="80" max-width="80" src="/img/logo.png" />
                        </v-card-title>
                        <v-divider></v-divider>
                        <div class="unsubmited" v-if="!submited">
                            <v-card-text>
                                <br>
                                <div class="text">
                                    Seteaza o noua parola pentru contul tau Chartarium
                                </div><br>
                                <v-text-field outlined label="Parola noua" type="password" v-model="credentials.password" ref="email" :rules="[() => !!credentials.email || 'This field is required']"></v-text-field>
                                <v-text-field outlined label="Confirmare parola noua" type="password" v-model="credentials.password_confirmation" ref="email" :rules="[() => !!credentials.email || 'This field is required']"></v-text-field>
                                <div class="errors" v-if="errors" style="color: #FF5252;">
                                    <br>
                                    <div class="message">
                                        {{ errors.message}}
                                    </div>
                                    <div class="items">
                                        <div class="item" v-for="error in errors.errors">
                                            {{ error[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn :disabled="loading" color="primary" type="submit" block>Schimba Parola</v-btn>
                            </v-card-actions>
                        </div>
                        <div class="unsubmited" v-if="submited">
                            <v-card-text>
                                <br>
                                <div class="text">
                                    Parola ta a fost schimbata.<br><br>
                                    <a href="https://account.chartarium.ro">Mergi la login</a>
                                </div><br>
                            </v-card-text>
                        </div>
                    </v-card>
                </form>
            </v-col>
        </v-row>
        <div class="no-mobile">
            <div class="text">
                <b>ATENTIE!</b> Contul tau este deja creat, insa pentru a te bucura in continuare de fisele tale, este necesar sa accesezi platforma pe dispozitive tip PC sau laptop. <br><br>Va rugam sa va autentificati pe un dispozitiv eligibil. <br><br>
                <a href="https://chartarium.ro/site/what">
                    <v-btn color="primary" type="submit" block>
                        Mergi catre website
                    </v-btn>
                </a>
            </div>
        </div>
    </v-container>
</template>
<style scoped></style>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
export default {
    name: "Login",
    data: () => ({
        valid: true,
        loading: false,
        credentials: {
            password: "",
            password_confirmation: "",
        },
        submited: false,
        errors: null,
    }),
    computed: mapGetters(["loggedIn"]),
    mounted() {
        console.log(this.$route);
    },
    methods: {
        ...mapActions(["login", "user"]),
        async onSubmit(e) {
            try {
                e.preventDefault();
                this.errors = null;
                let response = await axios.post('auth/confirmResetPassword', {
                    ...this.credentials,
                    token: this.$route.query.token,
                    email: this.$route.query.email,
                });
                this.submited = true;
            } catch (error) {
                if(error.response) {
                    if(error.response.status != 500) {
                        this.errors = error.response.data;    
                    } else {
                        this.$toasted.error(error.response.data.error);
                    }
                    
                } 
            }
        },
    },
};
</script>