<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="3">
                <form @submit="onSubmit">
                    <v-card class="mt-20">
                        <v-card-title class="justify-center">
                            <v-img max-height="80" max-width="80" src="/img/logo.png" />
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-text-field outlined label="Email" type="text" v-model="credentials.email" ref="email" :rules="[() => !!credentials.email || 'This field is required']"></v-text-field>
                            <v-text-field outlined label="Password" type="password" v-model="credentials.password" ref="password" :rules="[() => !!credentials.password || 'This field is required']"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :disabled="loading" color="primary" type="submit" block>Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
                <div class="forgot">
                    <br>
                    <router-link to="reset-password">
                        Ai uitat parola?
                    </router-link>
                </div>
            </v-col>
        </v-row>
        <div class="no-mobile">
            <div class="text">
                <b>ATENTIE!</b> Contul tau este deja creat, insa pentru a te bucura in continuare de fisele tale, este necesar sa accesezi platforma pe dispozitive tip PC sau laptop. <br><br>Va rugam sa va autentificati pe un dispozitiv eligibil. <br><br>
                <a href="https://chartarium.ro/site/what">
                    <v-btn color="primary" type="submit" block>
                        Mergi catre website
                    </v-btn>
                </a>
            </div>
        </div>
    </v-container>
</template>
<style scoped></style>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Login",
    data: () => ({
        valid: true,
        loading: false,
        credentials: {
            email: "",
            password: "",
        },
    }),
    computed: mapGetters(["loggedIn"]),
    methods: {
        ...mapActions(["login", "user"]),

        onSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.valid = true;
            var self = this;

            Object.keys(this.credentials).forEach((item) => {
                let fieldValid = this.$refs[item].validate(true);
                // Unvalidate form if any fields are not valid
                if (!fieldValid) {
                    this.valid = false;
                }
            });
            if (!this.valid) {
                this.loading = false;
                return;
            }

            this.login({
                    email: this.credentials.email,
                    password: this.credentials.password,
                })
                .then(() => {
                    self.loading = false;
                    if (this.loggedIn === true) {
                        this.user({
                            email: this.credentials.email,
                            password: this.credentials.password,
                        })
                        this.$router.push({ path: "/" });
                    } else {
                        this.valid = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.valid = false;
                });
        },
    },
};
</script>