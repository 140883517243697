<template>
    <div class="component header">
        <div class="navigation" data-aos="fade-down">
            <v-container style="max-width: 1400px;">
                <div class="logo">
                    <img src="https://chartarium.ro/img/logo.8dd1efbd.svg" alt="">
                </div>
                <div class="user logged-out" style="padding-top: 15px;">
                    <a href="https://chartarium.ro" style="color: inherit;">
                        <v-icon style="font-size: 20px;">mdi-chevron-left</v-icon> Inapoi la site
                    </a>
                </div>
            </v-container>
        </div>
    </div>
</template>
<style lang="scss">
@import "RegisterHeader.scss";
</style>
<script>
export default {
    name: "HeaderComponent",
    watch: {
        '$route': {
            deep: true,
            handler() {
                this.navigation = false;
            }
        }
    },
    data() {
        return {
            navigation: false,
            menu: [{
                    label: 'Home',
                    route: '/site/home',
                },
                {
                    label: 'Ce este chartarium?',
                    route: '/site/what',
                },
                {
                    label: 'Cine il poate utiliza?',
                    route: '/site/who',
                },
                {
                    label: 'Functionalitati',
                    route: '/site/functionality',
                },
                {
                    label: 'Despre Noi',
                    route: '/site/about',
                },
                {
                    label: 'Tarife',
                    route: '/site/pricing',
                }
            ]
        }
    }
}
</script>