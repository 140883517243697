<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="3">
                <form @submit="onSubmit">
                    <v-card class="mt-20">
                        <v-card-title class="justify-center">
                            <v-img max-height="80" max-width="80" src="/img/logo.png" />
                        </v-card-title>
                        <v-divider></v-divider>
                        <div class="unsubmited" v-if="!submited">
                            <v-card-text>
                                <br>
                                <div class="text">
                                    Adauga adresa de email mai jos pentru a primi un email de resetare a parolei
                                </div><br>
                                <v-text-field outlined label="Email" type="text" v-model="credentials.email" ref="email" :rules="[() => !!credentials.email || 'This field is required']"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn :disabled="loading" color="primary" type="submit" block>Reseteaza Parola</v-btn>
                            </v-card-actions>
                        </div>

                        <div class="unsubmited" v-if="submited">
                            <v-card-text>
                                <br>
                                <div class="text">
                                    Un email de resetare a parolei a fost trimis la adresa <b>{{ credentials.email }}</b>.<br><br>Te rugam urmareste instructiunile din email pentru a finaliza resetarea.
                                </div><br>
                            </v-card-text>
                        </div>
                    </v-card>
                </form>
            </v-col>
        </v-row>
        <div class="no-mobile">
            <div class="text">
                <b>ATENTIE!</b> Contul tau este deja creat, insa pentru a te bucura in continuare de fisele tale, este necesar sa accesezi platforma pe dispozitive tip PC sau laptop. <br><br>Va rugam sa va autentificati pe un dispozitiv eligibil. <br><br>
                <a href="https://chartarium.ro/site/what">
                    <v-btn color="primary" type="submit" block>
                        Mergi catre website
                    </v-btn>
                </a>
            </div>
        </div>
    </v-container>
</template>
<style scoped></style>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
export default {
    name: "Login",
    data: () => ({
        valid: true,
        loading: false,
        credentials: {
            email: "",
        },
        submited: false,
    }),
    computed: mapGetters(["loggedIn"]),
    methods: {
        ...mapActions(["login", "user"]),
        async onSubmit(e) {
            try {
                e.preventDefault();
                if (this.credentials.email.length <= 2) {
                    this.$toasted.error('Te rugam completeaza adresa de email');
                    return;
                }
                let response = await axios.post('auth/resetPassword', this.credentials);
                this.submited = true;
            } catch (error) {
                this.$toasted.error(error.response.data.error);
                console.log(error);
            }
        },
    },
};
</script>