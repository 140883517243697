<template>
    <div class="view register">
        <register-header />
        <div class="content">
            <div class="bg">
                <img src="@/assets/register/bg.png" alt="">
            </div>
            <v-container class="head">
                <v-row>
                    <v-col>
                        <div class="name">Creare Cont</div>
                    </v-col>
                    <v-col>
                        <div class="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderi.
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="form" id="#pas1">
                <div class="name">Pasul 1: Detaliile contului</div>
                <div class="fields">
                    <v-row  class="justify-center">
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="temp.name" label="Nume" outlined></v-text-field>
                            <v-text-field type="password" v-model="temp.password" label="Parola" outlined></v-text-field>
                        </v-col>
                        <v-col class="bcol" cols="12"  sm="4">
                        	<v-text-field v-model="temp.email" label="Email" outlined></v-text-field>
                        	<v-text-field type="password" v-model="temp.password_confirmation" label="Confirma Parola" outlined></v-text-field>
                        </v-col>
                       <!--  <v-col>
                        	<div class="profile">
                        		<div class="label">
                        			Imagine de profil<br>
                        			(optional)
                        		</div>
                        		<div class="circle">
                        			<div class="plus">
                        				+
                        			</div>
                        			<div class="preview">
                        				
                        			</div>
                        		</div> 
                        	</div>
                        </v-col> -->
                    </v-row>
                    <v-row  class="justify-center">
                        <v-col cols="8">
                            <div class="errors" v-if="errors">
                                <div class="message">
                                    {{ errors.message}}
                                </div>
                                <div class="items">
                                    <div class="item" v-for="error in errors.errors">
                                        {{ error[0] }}
                                    </div>
                                </div>
                            </div>     
                        </v-col>
                    </v-row>
                </div>
            </v-container>
            <v-container class="plan pricing">
            	<div class="name">Pasul 2: Alege planul</div>
            	<v-row class="top">
        			<v-col cols="12" class="text-center">
        				<div @click="toggleSwitch" class="switch" :class="{'active': type == 'organization'}">
        					<div class="side l">INDIVIDUAL</div>
        					<div class="side r">ORGANIZATII</div>
        				</div>
        			</v-col>
        		</v-row>
        		<v-row class="plans pricing">
        			<v-col cols="12" sm="4" v-for="plan in filteredPlans">
        				<div class="plan" :class="{'active': plan.id == temp.plan_id}" @click="temp.plan_id = plan.id">
        					<div class="name">{{ plan.name }}</div>
        					<div class="options">
        						<div class="option">
        							<div class="value">{{ plan.max_users }}</div>
        							<div class="label">Useri:</div>        							
        						</div>
        						<div class="option">
        							<div class="value">{{ (plan.max_folders == 0) ? 'Nelimitat' : plan.max_folders }}</div>
        							<div class="label">Foldere create:</div>        							
        						</div>
        						<div class="option">
        							<div class="value">{{ (plan.resource_access == 1) ? 'Nelimitat' : 'Limitat' }}</div>
        							<div class="label">Access resurse:</div>        							
        						</div>
        						<div class="option">
        							<div class="value">{{ (plan.resource_edit == 1) ? 'Nelimitat' : 'Limitat' }}</div>
        							<div class="label">Editare resurse:</div>        							
        						</div>
        						<div class="option">
        							<div class="value">
                                        <v-icon v-if="plan.edit_logo == 0">mdi-close</v-icon>
                                        <v-icon v-if="plan.edit_logo == 1">mdi-check</v-icon>
                                    </div>
        							<div class="label">Personalizare resurse cu logo:</div>        					
        						</div>
        						<div class="option">
        							<div class="value">
                                        <v-icon v-if="plan.edit_text == 0">mdi-close</v-icon>
                                        <v-icon v-if="plan.edit_text == 1">mdi-check</v-icon>
                                    </div>
        							<div class="label">Personalizare resurse cu text:</div>        				
        						</div>
        						<div class="option">
        							<div class="value">A{{ plan.print_level }}</div>
        							<div class="label">Print:</div>        					
        						</div>
        						<div class="option">
        							<div class="value">
                                        <v-icon v-if="plan.print_levell == 0">mdi-check</v-icon>
                                        <v-icon v-else>mdi-close</v-icon>
                                    </div>
        							<div class="label">Primt marimi speciale:</div>        					
        						</div>
        					</div>
                            <div class="description" style="height: 50px; margin-top: 50px; font-weight: bold;  color:  #6d3acf;">
                                {{ plan.description }}
                            </div>
        					<div class="price">
        						{{ (plan.price == 0) ? 'GRATIS' : plan.price / 100 + ' RON / luna' }}
        					</div>
        				</div>
        			</v-col>
        		</v-row>
            </v-container>
            <v-container class="next">
            	<v-btn flat @click="submit"><v-icon>mdi-check</v-icon> CONTINUA</v-btn>
            </v-container>
        </div>
    </div>
</template>
<style lang="scss">
@import "RegisterView.scss";
</style>
<script>
import RegisterHeader from './Components/Header/RegisterHeader.vue';
export default {
    name: "RegisterView",
    data() {
        return {
        	temp: {
        		name: '',
        		email: '',
        		password: '',
        		password_confirmation: '',
                plan_id: 2,
        	},
            type: 'personal',
            plans: null,
            errors: null,
        }
    },
    components: {
        RegisterHeader,
    },
    async mounted() {
        await this.getPlans();
        if(this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if(this.$route.query.planId) {
            this.temp.plan_id = this.$route.query.planId;
        }

        console.log(this.$route);

    },
    computed: {
        filteredPlans() {
            if(!this.plans) {
                return [];
            }
            return this.plans.filter(e => {
                return (e.type == this.type);
            });
        }
    },
    methods: {
        async toggleSwitch() {
            if(this.type == 'personal') {
                this.type = 'organization';
                await this.$nextTick();
                this.temp.plan_id = this.filteredPlans[0].id;
            } else {
                this.type = 'personal';
                await this.$nextTick();
                this.temp.plan_id = this.filteredPlans[0].id;
            }
        },
        async getPlans() {
            let response = await this.$axios.get('https://api.chartarium.ro/api/open/plans');
            this.plans = response.data;
        },
        async submit() {
            this.errors = null;
            try {
                let response = await this.$axios.post('https://api.chartarium.ro/api/app/auth/registerAndJoin', this.temp);
                console.log(response.data);
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("account", JSON.stringify(response.data.account));
                localStorage.setItem("token", JSON.stringify(response.data.token));
                window.location.href = response.data.redirect;
            } catch (error) {
                document.getElementById("#pas1").scrollIntoView();
                this.$toasted.error('Eroare de validare');
                if(error.response) {
                    this.errors = error.response.data;
                } 
            }
        }
    }
}
</script>